import { TypoSBold, TypoXXS, TypoXXXSBold } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import IconCoin from '@svg-icons/IconCoin';
import { getCoinValue } from '@utils/coin';
import { VIP_DISCOUNT_RATE } from '@utils/constants';
import { Coin } from '@utils/types';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  coin: Coin;
  nights?: number | null;
  noOfGuest?: number;
};

const CoinInfoWithGuestsAndNights = ({
  coin,
  nights,
  noOfGuest,
}: Props): JSX.Element => {
  const { loadingAuthUser } = useAppSelector((state) => ({
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { vipRank } = useNeighbor();
  const isVip = vipRank !== 'regular';

  const isCoinFetched = ![coin].every(
    (value) => value === undefined || value === null
  );
  const { t } = useTranslation('property');
  const guestsText =
    (noOfGuest &&
      `${t('planCalendar.guestNumberUnit', {
        guestNumber: noOfGuest,
      })} / `) ||
    '';

  const nightsText = t('stayUnit', {
    stay: nights || 1,
  });

  if (loadingAuthUser || !isCoinFetched) {
    return <Image alt="loading" height={8} src="/loading.gif" width={30} />;
  }

  return (
    <div className="coin-info-nights-and-guests">
      {(noOfGuest || nights) && (
        <div className="nights-and-guests">
          <TypoXXS color="black600" text={`${guestsText}${nightsText}`} />
        </div>
      )}
      <div className="coin-info-wrapper">
        <div className="coin-info-discount">
          <IconCoin size="sm" withFill={true} />
          <TypoSBold
            text={`${getCoinValue(
              Array.isArray(coin) ? coin[0] : coin,
              isVip ? vipRank : 'base'
            )}~`}
          />
        </div>
        {isVip && (
          <>
            <div className="coin-info-content">
              <IconCoin color="black600" size="xxs" withFill={false} />
              {getCoinValue(coin, 'base')}~
            </div>
            {isVip && (
              <div className="vip-badge">
                <TypoXXXSBold
                  color="white"
                  text={`${VIP_DISCOUNT_RATE[vipRank]}%OFF`}
                />
              </div>
            )}
          </>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CoinInfoWithGuestsAndNights;
